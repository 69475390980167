export const URLS = {
  searchCars: `/stock/stock-management/cars/search`,
  carDetail: (carId?: string) => `/stock/stock-management/cars/${carId}`,
  carStateUpdate: (carId?: string) =>
    `/stock/stock-management/cars/${carId}/states`,
  carDetailUpdate: (carId?: string) => `/stock/stock-management/cars/${carId}`,
  carDetailPriceUpdate: (carId?: string) =>
    `/stock/stock-management/cars/${carId}/price`,
  getSalesStatesStats: '/stock/stock-management/cars/states-stats',
  getCarInfoPdf: (carId?: string) =>
    `/stock/stock-management/cars/${carId}/export-pdf`,
  deleteCarDetailPhotos: '/stock/stock-management/photos',

  lovTransmissions: '/lov/transmissions',
  lovFuelTypes: '/lov/fuel-types',
  lovMakes: '/lov/makes',
  lovBodies: '/lov/bodies',
  lovModelsSearch: '/lov/models/search',
  lovEnginesSearch: '/lov/engines/search',
  lovStandardRepairs: '/lov/standard-repairs/search',
  lovServiceContractors: '/lov/service-contractors/search',
  lovTechnicalConditionSections: '/lov/technical-condition-sections',
  lovTechnicalConditionItems: '/lov/technical-condition-items/search',
  lovTechnicalConditionSubcategorySearch:
    '/lov/technical-condition-subcategories/search',
  lovTechnicalConditionCategorySearch:
    '/lov/technical-condition-categories/search',

  lovMarketingLabels: '/stock/stock-management/cars/marketing-labels',
  lovMarketingHighlights: '/stock/stock-management/cars/marketing-highlights',

  employeesLogin: '/employees/login',
  employeesTokenRefresh: '/employees/tokens/refresh',

  searchFiles: '/admin/files/search',

  carAggregators: (carId?: string) => `/aggregator/stock-app/cars/${carId}`,
  carCreateAggregators: (carId?: string) =>
    `/aggregator/stock-app/cars/${carId}/sync`,
  carDeleteAggregators: (carId?: string) =>
    `/aggregator/stock-app/cars/${carId}/desync`,
  carAggregatorExport: (carId?: string) =>
    `/aggregator/stock-app/cars/export/${carId}`,
  carDetailUpdatePhotoOrder: (carId?: string) =>
    `/stock/stock-management/cars/${carId}/photos-order`,
  searchServiceOrders: (params: {
    page: number
    size: number
    sort?: string[]
  }) =>
    `/stock/stock-management/service-orders/search?page=${params.page}&size=${
      params.size
    }&sort=${params.sort ?? []}`,
  serviceOrderDetail: (serviceOrderId?: string) =>
    `/stock/stock-management/service-orders/${serviceOrderId}`,
  serviceOrderApproval: (serviceOrderDetail?: string) =>
    `/stock/stock-management/service-orders/${serviceOrderDetail}/approve`,
  serviceOrderDecline: (serviceOrderDetail?: string) =>
    `/stock/stock-management/service-orders/${serviceOrderDetail}/decline`,
  vehicleCosts: (carId?: string) =>
    `/stock/stock-management/cars/${carId}/costs`,
  vinBlockList: '/aggregator/stock-app/cars/blacklist',
  carsReporting: '/stock/stock-management/cars/statistics',
  carStateHistory: (carId?: string) =>
    `/stock/stock-management/cars/${carId}/car-state-history`,
  carPriceHistory: (carId?: string) =>
    `/stock/stock-management/cars/${carId}/price-history`,
  carLocationHistory: (carId?: string) =>
    `/stock/stock-management/cars/${carId}/location-history`,
  prematureSelling: (carId: string) =>
    `/stock/stock-management/cars/${carId}/premature-selling`,

  orderInit: '/admin/orders/order-management/pw/init',
  orderDetail: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}`,
  orderAssign: (orderId?: string, userId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/assign/${userId}`,
  orderNotes: '/admin/orders/notes/search',
  orderHomeDeliveryInfo: '/orders/home-delivery/info',
  orderBasicsOwner: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/basics-owner`,
  orderCarRegistration: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/car-registration`,
  orderExtendedWarranty: (orderId?: string) =>
    `/admin/orders/order-management/pw/extended-warranty/${orderId}`,
  orderOwnerCompany: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/owner/company`,
  orderOwnerSelfEmployed: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/owner/self-employed`,
  orderOwnerPerson: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/owner/person`,
  orderSummary: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/summary`,
  orderDelivery: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/delivery`,
  orderHomeDelivery: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/home-delivery`,
  orderConfirmation: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/confirmation`,
  orderCarRegistrationNoStepChange: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/car-registration-no-step-change`,
  orderBasicsCountry: (orderId?: string) =>
    `/admin/orders/order-management/pw/${orderId}/basics-country`,
  customerDetail: (customerId?: string) => `/admin/customers/${customerId}`,
  customerSelf: '/customers/self',
  customerSessionRefresh: '/customers/tokens/refresh',

  searchOrders: '/admin/orders/order-management/orders/search',
  checkPhone: `/api/auth/phone/check`,
  checkEmail: `/api/auth/email/check`,
  resetEmailPassword: `/api/auth/email/reset`,
  indecisiveCustomer: (orderId?: string) => `/orders/${orderId}/indecision`
}

export const STATIC_DATA_REVALIDATION_TIME = 1800 // 30 minutes
